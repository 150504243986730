<template>
  <b-modal
    id="modalSystemSettingsSMSProvider"
    v-model="isOpen"
    title="Configurações do Provedor de SMS"
    hide-header-close
    centered
    ok-title="Salvar"
    cancel-title="Fechar"
    no-close-on-backdrop
    @hide="onHide"
    @hidden="onHide"
    @ok="handleOk"
    @cancel="onClose"
  >
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Configurações</span>
        </template>
        <b-row class="align-items-center">
          <b-col md="12">
            <i2-form-vue-select
              id="settings-provider"
              v-model="settings.provider"
              label="Provedor"
              :options="SMSProviderTypes()"
              name="settings-provider"
              rules="required"
            />
          </b-col>
          <b-col md="12">
            <i2-form-input
              id="settings-sms-provider-url"
              v-model="settings.url"
              name="settings-sms-provider-url"
              label="URL"
              placeholder="https://www.exemplo.com.br/"
              type="text"
              rules="required"
            />
          </b-col>
          <b-col md="12">
            <i2-form-input
              id="settings-sms-provider-cost_centre_id"
              v-model="settings.cost_centre_id"
              name="settings-sms-provider-cost_centre_id"
              label="Centro de Custo"
              placeholder="99999"
              type="text"
              rules="required"
            />
          </b-col>
          <b-col md="12">
            <i2-form-input
              id="settings-from-email"
              v-model="settings.token"
              name="settings-sms-provider-token"
              label="Token"
              placeholder="eyJhbGciOiJIUz********"
              type="text"
              rules="required"
            />
          </b-col>
          <b-col md="12">
            <i2-form-input
              id="settings-from-expires-in"
              v-model="settings.expires_in"
              name="settings-sms-expires-in"
              label="Expira em (Minutos)"
              placeholder="10"
              type="number"
              rules="required"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body font-small-2">
                <span>Está configuração será aplicada apenas para a empresa que o seu usuário está logada.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row v-show="errorMessage">
          <b-col>
            <b-alert
              variant="danger"
              show
            >
              <h4 class="alert-heading">
                Aviso!
              </h4>
              <div class="alert-body">
                <small>{{ errorMessage }}</small>
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="MessageSquareIcon" />
          <span>Mensagem</span>
        </template>
        <b-row>
          <b-col>
            <b-form-textarea
              v-model="settings.text_message"
              placeholder="Digite o texto da mensagem a ser enviada."
              rows="3"
              max-rows="6"
            />
            <i2-divider />
            <b-list-group>
              <b-list-group-item v-for="variable in variables"
                                 :key="variable.name"
                                 v-b-tooltip.hover.bottom="variable.description"
                                 @click="copyToClipboard(variable.name)"
              >
                <small>{{ variable.name }}</small>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>

import SMSProviderTypes from '@/variables/SMSProviderTypes'
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'SystemSettingsSMSProvider',
  components: {
    I2Divider,
    BListGroupItem,
    BListGroup,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      errorMessage: '',
      settings: {
        provider: 'MKOM',
        url: '',
        cost_centre_id: '',
        token: '',
        expires_in: 10,
        text_message: '',
      },
      variables: [
        {
          name: 'code',
          description: 'Código Gerado',
        },
        {
          name: 'company_name',
          description: 'Nome da Empresa',
        },
        {
          name: 'promotion_name',
          description: 'Nome da Promoção',
        },
        {
          name: 'participant_name',
          description: 'Nome do Participante',
        },
      ],
    }
  },
  watch: {
    open(value) {
      this.isOpen = value
      if (value) {
        this.getData()
      }
    },
  },
  methods: {
    SMSProviderTypes() {
      return SMSProviderTypes
    },
    getData() {
      this.$http.get('system-settings/sms-provider').then(
        response => {
          if (response.settings !== null) {
            this.settings = response.settings
          }
        },
      )
    },
    handleOk(bvModal) {
      bvModal.preventDefault()
      let withError = false
      for (let prop in this.settings) {
        if (this.settings[prop] === '') {
          withError = true
        }
      }

      if (withError) {
        this.$notify.error('Todos os campos são de preenchimento obrigatório.')
        return false
      }
      this.save()
      return true
    },
    async save() {
      const response = await this.$http.put('system-settings/sms-provider', this.settings)
      this.settings = response.settings
      this.$notify.success('Configuração do provedor de SMS salva com sucesso.')
      this.onClose()
    },
    onClose() {
      this.errorMessage = ''
      this.$emit('onClose', true)
    },
    onHide() {
      return false
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(`{{${text}}}`)
    },
  },
}
</script>

<style scoped>

</style>
